import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthNav from './auth-nav';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import rules from "../rbac-rules";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.navbarClick = this.navbarClick.bind(this);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';

    var role = "";
    if (user !== undefined) {
      role = user[roleNamespace];
    } else {
      role = "";
    }
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      mobileToggler: "collapse",
      ariaExpanded: "false",
      navbarClass: "collapse navbar-collapse"
    };
  }

  navbarClick() {
    if (this.state.mobileToggler === "collapse") {
      this.setState({
        mobileToggler: "show",
        ariaExpanded: "true",
        navbarClass: "collapse navbar-collapse show"
      })
    } else {
      this.setState({
        mobileToggler: "collapse",
        ariaExpanded: "false",
        navbarClass: "collapse navbar-collapse"
      })
    }
  }

  render() {
    return (
      <nav className='navbar navbar-expand-lg fixed-top shadow' style={{ background: 'linear-gradient(90deg, #2c3e50, #1a2530)' }}>
        <div className="container">
          <Link to="/" className="navbar-brand fw-bold text-light d-flex align-items-center">
            <span>searchboss</span>
          </Link>
          <button
            className="navbar-toggler border-0 text-light"
            type="button"
            data-bs-toggle={this.state.mobileToggler}
            data-bs-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded={this.state.ariaExpanded}
            aria-label="Toggle navigation"
            onClick={() => { this.navbarClick() }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={this.state.navbarClass} id="navbarToggler">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <Can
                role={this.state.role}
                perform="accounts:view"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people me-1" viewBox="0 0 16 16">
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                      </svg>
                      &nbsp;Accounts
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
              <Can
                role={this.state.role}
                perform="accounts:add"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/account/create" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle me-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                      </svg>
                      &nbsp;Create Account
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
              <Can
                role={this.state.role}
                perform="users:view"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/users/" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person me-1" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                      </svg>
                      &nbsp;Users
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
              <Can
                role={this.state.role}
                perform="users:add"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/user/create" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus me-1" viewBox="0 0 16 16">
                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                        <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                      </svg>
                      &nbsp;Create User
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
              <Can
                role={this.state.role}
                perform="accounts:boost"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/boost/domains" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up-arrow me-1" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
                      </svg>
                      &nbsp;Boost
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
              <Can
                role={this.state.role}
                perform="accounts:boost"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/boost/create" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe me-1" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
                      </svg>
                      &nbsp;Create Domain
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
              <Can
                role={this.state.role}
                perform="orgs:stats"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/org/stats" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart me-1" viewBox="0 0 16 16">
                        <path d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z"/>
                      </svg>
                      &nbsp;Usage Stats
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
              <Can
                role={this.state.role}
                perform="orgs:stats"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/social/stats" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share me-1" viewBox="0 0 16 16">
                        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
                      </svg>
                      &nbsp;Social Stats
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
              <Can
                role={this.state.role}
                perform="orgs:add"
                yes={() => (
                  <li className="nav-item mx-1">
                    <Link to="/org/create" className="nav-link text-light px-3 py-2 rounded-pill hover-effect" onClick={() => { this.navbarClick() }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-building-add me-1" viewBox="0 0 16 16">
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Z"/>
                        <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1V1Z"/>
                        <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/>
                      </svg>
                      &nbsp;Create Organization
                    </Link>
                  </li>
                )}
                no={() => ("")}
              />
            </ul>
            <div className="d-none d-lg-block">
              <AuthNav />
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withAuth0(Navbar);
