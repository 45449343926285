import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

const Page = (props) => (
  <tr className="page-row">
    <td>
      <Link
        to={"/account/" + props.account + "/page/" + props.page._id}
        className="page-link text-decoration-none"
      >
        <i className="bi bi-file-earmark me-2"></i>
        {props.page.url}
      </Link>
    </td>
    <td>
      <span
        className={`badge ${props.warningCount > 3 ? "bg-danger" : "bg-warning"} rounded-pill py-2 px-3`}
      >
        {props.warningCount}
      </span>
    </td>
    <td className="d-none d-lg-table-cell text-wrap">
      <small className="text-muted">{props.warnings}</small>
    </td>
  </tr>
);

const Note = (props) => (
  <tr className="note-row">
    <td className="p-3">
      <div className="note-content p-3 bg-light rounded">
        <pre className="mb-0">{props.note}</pre>
      </div>
    </td>
    <td>
      <small className="text-muted">
        <i className="bi bi-clock me-1"></i>
        {props.time}
      </small>
    </td>
    <td className="d-none d-lg-table-cell">
      <small>
        <i className="bi bi-person me-1"></i>
        {props.user}
      </small>
    </td>
  </tr>
);

class AccountHome extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = "https://searchboss.app/role";
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      user: "",
      currentUser: user.email,
      accounturl: "",
      robots: "",
      startdate: "",
      status: "",
      boostlinks: "",
      industry: "",
      socialLocationId: "",
      socialPostCount: 0,
      contacts: "",
      excludedWords: "",
      info: "",
      pages: [],
      notes: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false,
    };
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
      headers: { Authorization: "Bearer " + token },
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:view")) {
      const config = await this.config();

      axios
        .get(
          process.env.REACT_APP_API +
            "/account/home/" +
            this.props.match.params.id,
          config,
        )
        .then((response) => {
          console.log(response.data);
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            startdate: response.data.startdate,
            status: response.data.status,
            boostlinks: response.data.boostlinks,
            industry: response.data.industry,
            socialLocationId: response.data.socialLocationId,
            socialPostCount: response.data.socialPostCount,
            contacts: response.data.contacts,
            excludedWords: response.data.excludedWords,
            contentContext: response.data.contentContext,
            info: response.data.info,
            pages: response.data.pages,
            notes: response.data.notes,
            crawling: response.data.crawling,
            robots: response.data.robots,
            id: this.props.match.params.id,
            isReady: true,
          });
        })
        .catch((err) => console.log(err))
        .then((response) => {
          document.title = this.state.accountname + " - SearchBoss";
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      return null;
    }
  }

  async crawlAccount(id) {
    if (this.state.staticRules.includes("accounts:crawl")) {
      const config = await this.config();
      const data = null;

      axios
        .get(
          process.env.REACT_APP_API + "/account/" + id + "/crawling/",
          config,
        )
        .then((response) => {
          const date = new Date();
          const dateString = date.toDateString();

          if (response.data.crawling === false) {
            if (response.data.lastCrawl === dateString) {
              alert("On-demand crawl already completed today.");
            } else {
              axios
                .post(
                  process.env.REACT_APP_API + "/account/crawl/" + id,
                  data,
                  config,
                )
                .then(console.log("Crawl started!"))
                .catch((error) => {
                  console.log(error);
                });

              this.setState({
                crawling: true,
              });
            }
          } else {
            alert("Crawl already in progress.");
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert(
        "This access level does not have permission to crawl accounts. Please contact your administrator.",
      );
    }
  }

  pagesList() {
    return this.state.pages.map((page) => {
      const warnings = [];

      if (page.h1count === 0) {
        warnings.push("No H1's.");
      }
      if (page.h1count >= 2) {
        warnings.push("Two or more H1's.");
      }
      if (page.h2count === 0) {
        warnings.push("No H2's.");
      }
      if (page.h3count <= 1) {
        warnings.push("One or less H3's.");
      }
      if (page.titleLength === undefined) {
        warnings.push("Title tag missing.");
      }
      if (page.titleLength <= 40) {
        warnings.push("Title tag too short.");
      }
      if (page.titleError === true) {
        warnings.push("Multiple title tags.");
      }
      if (page.metaDesLen === undefined) {
        warnings.push("Missing meta description.");
      }
      if (page.metaDesLen <= 50) {
        warnings.push("Meta description too short.");
      }
      if (page.metaDesLen >= 200) {
        warnings.push("Meta description too long.");
      }
      if (page.statusCode !== 200) {
        warnings.push("Status code " + page.statusCode + " found.");
      }

      if (page.robots.some((robot) => robot.includes("noindex"))) {
        warnings.push("Noindexed via meta tag.");
      }

      if (page.robots.some((robot) => robot.includes("nofollow"))) {
        warnings.push("Nofollowed via meta tag.");
      }

      const warningCount = warnings.length;
      var warningDetails = "";

      if (warningCount !== 0) {
        warningDetails = warnings.join(" ");
        return (
          <Page
            account={this.props.match.params.id}
            page={page}
            warningCount={warningCount}
            warnings={warningDetails}
            key={page._id}
          />
        );
      }
    });
  }

  notesList() {
    var sortedNotes = this.state.notes.reverse();

    return sortedNotes.map((note) => {
      return (
        <Note
          note={note.note}
          time={note.updatedAt}
          user={note.user}
          key={note._id}
        />
      );
    });
  }

  crawling() {
    if (this.state.crawling === true) {
      return (
        <div className="alert alert-info d-flex align-items-center">
          <i className="bi bi-gear-wide-connected me-2 fa-spin"></i>
          <span>Crawl currently in progress</span>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    const initialValues = {
      user: this.state.currentUser,
    };

    // initialize Yup validation
    const addNoteSchema = Yup.object().shape({
      note: Yup.string().required("Note is required."),
      user: Yup.string().required("User is required."),
    });

    return (
      <Can
        role={this.state.role}
        perform="accounts:view"
        yes={() => (
          <div className="account-home-container">
            <div className="card shadow-sm mb-4">
              <div
                className="card-header py-3"
                style={{
                  background: "linear-gradient(90deg, #2c3e50, #1a2530)",
                }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <h1 className="card-title mb-0 text-white d-flex align-items-center">
                      <i className="bi bi-building me-3"></i>
                      {this.state.accountname}
                    </h1>
                    <a
                      href={this.state.accounturl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none d-flex align-items-center mt-2"
                    >
                      <i className="bi bi-globe me-2 text-white-50"></i>
                      <span className="text-white-50">
                        {this.state.accounturl}
                      </span>
                    </a>
                  </div>
                  <div className="col-lg-5 text-lg-end">{this.crawling()}</div>
                </div>
              </div>

              <div className="card-body">
                <div className="row g-3 mb-4">
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center mb-3">
                      <div className="me-4">
                        <span className="mr-1 font-weight-bold">Status:</span>
                        <span
                          className={`badge text-white ${
                            this.state.status === "Active"
                              ? "bg-success"
                              : this.state.status === "On Hold"
                                ? "bg-warning"
                                : this.state.status === "Canceled"
                                  ? "bg-danger"
                                  : "bg-primary"
                          } ms-1`}
                        >
                          {this.state.status}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="font-weight-bold me-1">Start Date:</span>
                      <span> {this.state.startdate}</span>
                    </div>
                    <div className="mb-3">
                      <span className="font-weight-bold me-1">Contacts:</span>
                      <span> {this.state.contacts}</span>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <span className="font-weight-bold me-1">User:</span>
                        <span className="badge bg-light text-dark">
                          <i className="bi bi-person me-1"></i>
                          {this.state.user}
                        </span>
                      </div>
                    </div>

                    <div className="action-buttons d-flex flex-wrap gap-2 mb-3">
                      <Can
                        role={this.state.role}
                        perform="accounts:edit"
                        yes={() => (
                          <Link
                            className="btn btn-sm btn-outline-primary m-1"
                            to={"../../" + this.state.id + "/edit/"}
                          >
                            <i className="bi bi-pencil-square me-1"></i>Edit
                          </Link>
                        )}
                        no={() => null}
                      />
                      <Can
                        role={this.state.role}
                        perform="accounts:content"
                        yes={() => (
                          <Link
                            className="btn btn-sm btn-outline-primary m-1"
                            to={"../../../" + this.state.id + "/content/"}
                          >
                            <i className="bi bi-file-text me-1"></i>Content
                          </Link>
                        )}
                        no={() => null}
                      />
                      <Can
                        role={this.state.role}
                        perform="accounts:content"
                        yes={() => (
                          <Link
                            className="btn btn-sm btn-outline-primary m-1"
                            to={
                              "../../../" + this.state.id + "/content-existing/"
                            }
                          >
                            <i className="bi bi-arrow-repeat me-1"></i>Rewrites
                          </Link>
                        )}
                        no={() => null}
                      />
                      <Can
                        role={this.state.role}
                        perform="accounts:boost"
                        yes={() => (
                          <Link
                            className="btn btn-sm btn-outline-primary m-1"
                            to={"../../../" + this.state.id + "/boost/"}
                          >
                            <i className="bi bi-graph-up me-1"></i>Boost
                          </Link>
                        )}
                        no={() => null}
                      />
                      <Can
                        role={this.state.role}
                        perform="accounts:boost"
                        yes={() => (
                          <Link
                            className="btn btn-sm btn-outline-primary m-1"
                            to={"../../../" + this.state.id + "/social/"}
                          >
                            <i className="bi bi-share me-1"></i>Social
                          </Link>
                        )}
                        no={() => null}
                      />
                      <Can
                        role={this.state.role}
                        perform="accounts:productOpt"
                        yes={() => (
                          <Link
                            className="btn btn-sm btn-outline-primary m-1"
                            to={"../../../" + this.state.id + "/productOpt/"}
                          >
                            <i className="bi bi-box-seam me-1"></i>Products
                          </Link>
                        )}
                        no={() => null}
                      />
                      <Can
                        role={this.state.role}
                        perform="accounts:crawl"
                        yes={() => (
                          <button
                            className="btn btn-sm btn-outline-primary m-1"
                            onClick={() => {
                              this.crawlAccount(this.state.id);
                            }}
                          >
                            <i className="bi bi-search me-1"></i>Crawl
                          </button>
                        )}
                        no={() => null}
                      />
                      {this.state.status === "Sales Prospect" && (
                        <Can
                          role={this.state.role}
                          perform="accounts:prospect"
                          yes={() => (
                            <Link
                              className="btn btn-sm btn-outline-primary m-1"
                              to={"../../" + this.state.id + "/prospect/"}
                            >
                              <i className="bi bi-briefcase me-1"></i>Prospect
                            </Link>
                          )}
                          no={() => null}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="account-details card bg-light mb-4">
                  <div className="card-body">
                    <Can
                      role={this.state.role}
                      perform="accounts:boost"
                      yes={() => (
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <h5 className="text-primary mb-3">
                              <i className="bi bi-info-circle me-2"></i>
                              Account Information
                            </h5>
                            <div className="mb-2">
                              <span className="font-weight-bold me-1">
                                Boost Links:
                              </span>
                              <span className="badge text-white bg-primary m-1">
                                {this.state.boostlinks}
                              </span>
                            </div>
                            <div>
                              <span className="font-weight-bold me-1">
                                Boost Industry:
                              </span>
                              <span> {this.state.industry}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <h5 className="text-primary mb-3">
                              <i className="bi bi-geo-alt me-2"></i>
                              Google Business Profile
                            </h5>
                            <div className="mb-2">
                              <span className="font-weight-bold me-1">
                                GBP Location ID:
                              </span>
                              <span> {this.state.socialLocationId}</span>
                            </div>
                            <div>
                              <span className="font-weight-bold me-1">
                                GBP Monthly Posts:
                              </span>
                              <span className="badge text-white bg-primary m-1">
                                {this.state.socialPostCount}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      no={() => null}
                    />
                    <Can
                      role={this.state.role}
                      perform="accounts:content"
                      yes={() => (
                        <div className="row">
                          <div className="col-12">
                            <h5 className="text-primary mb-3">
                              <i className="bi bi-file-earmark-text me-2"></i>
                              Content Settings
                            </h5>
                            <div className="mb-2">
                              <span className="font-weight-bold me-1">
                                Excluded Words:
                              </span>
                              <span> {this.state.excludedWords}</span>
                            </div>
                            <div className="mb-2">
                              <span className="font-weight-bold">
                                Content Context:
                              </span>
                              <p className="mb-0 mt-1">
                                {this.state.contentContext}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      no={() => null}
                    />
                    {this.state.info && (
                      <div className="row mt-3">
                        <div className="col-12">
                          <h5 className="text-primary mb-2">
                            <i className="bi bi-sticky me-2"></i>
                            Notes
                          </h5>
                          <pre className="mb-0 bg-white p-3 rounded border">
                            {this.state.info}
                          </pre>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card shadow-sm mb-4">
              <div className="card-header bg-light py-3">
                <h3 className="card-title mb-0 d-flex align-items-center">
                  <i className="bi bi-journal-text me-2 text-primary"></i>
                  Account Notes
                </h3>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={addNoteSchema}
                  enableReinitialize={true}
                  onSubmit={async (values) => {
                    const config = await this.config();

                    if (this.state.crawling === false) {
                      axios
                        .post(
                          process.env.REACT_APP_API +
                            "/account/note/add/" +
                            this.props.match.params.id,
                          values,
                          config,
                        )
                        .then((res) => console.log(res.data))
                        .then(
                          setTimeout(() => {
                            window.location.reload(false);
                          }, 500),
                        )
                        .catch((err) => alert(err.response.data.message));
                    } else {
                      alert("Crawling right now, try again.");
                    }
                  }}
                >
                  {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                      <div className="mb-4">
                        <Form>
                          <div className="form-group mb-3">
                            <label
                              htmlFor="note"
                              className="form-label d-flex align-items-center"
                            >
                              <i className="bi bi-plus-circle me-2 text-success"></i>
                              <h5 className="mb-0">Create New Note</h5>
                            </label>
                            <Field
                              component="textarea"
                              name="note"
                              id="note"
                              rows="5"
                              className={`form-control ${errors.note && touched.note ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage
                              name="note"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group d-none">
                            <label htmlFor="user">User</label>
                            <Field
                              type="text"
                              name="user"
                              id="user"
                              className={`form-control ${errors.user && touched.user ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage
                              name="user"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className={`btn ${!(dirty && isValid) ? "btn-secondary" : "btn-primary"}`}
                              disabled={!(dirty && isValid)}
                            >
                              <i className="bi bi-save me-2"></i>
                              Add Note
                            </button>
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>

                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead className="table-light">
                      <tr>
                        <th>Note</th>
                        <th>Time</th>
                        <th className="d-none d-lg-table-cell">User</th>
                      </tr>
                    </thead>
                    <tbody>{this.notesList()}</tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card shadow-sm mb-4">
              <div className="card-header bg-light py-3 d-flex justify-content-between align-items-center">
                <h3 className="card-title mb-0 d-flex align-items-center">
                  <i className="bi bi-exclamation-triangle me-2 text-warning"></i>
                  Critical Page Errors
                </h3>
                <Link
                  to={"/account/" + this.state.id + "/pages/"}
                  className="btn btn-sm btn-outline-primary"
                >
                  <i className="bi bi-list me-1"></i>
                  View All Pages
                </Link>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>URL</th>
                        <th>Warnings</th>
                        <th className="d-none d-lg-table-cell">Details</th>
                      </tr>
                    </thead>
                    <tbody>{this.pagesList()}</tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card shadow-sm mb-4">
              <div className="card-header bg-light py-3">
                <h3 className="card-title mb-0 d-flex align-items-center">
                  <i className="bi bi-robot me-2 text-primary"></i>
                  Robots.txt File
                </h3>
              </div>
              <div className="card-body">
                <pre className="mb-0 bg-light p-3 rounded border">
                  {this.state.robots || "No robots.txt file found"}
                </pre>
              </div>
            </div>
          </div>
        )}
        no={() => (
          <div className="access-denied-container text-center p-5">
            <div className="card shadow border-0">
              <div className="card-body p-5">
                <i
                  className="bi bi-shield-lock text-danger"
                  style={{ fontSize: "4rem" }}
                ></i>
                <h2 className="mt-3 mb-2">Access Denied</h2>
                <p className="text-muted mb-4">
                  Your access level does not permit viewing account details.
                  Please contact your administrator for assistance.
                </p>
                <a href="/" className="btn btn-primary">
                  <i className="bi bi-arrow-left me-2"></i>
                  Return to Dashboard
                </a>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default withAuth0(AccountHome);
