import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";
import roles from "../rbac-roles";
import PendingPages from "../components/pending-pages";

// Extracted Content component with improved styling
const Content = ({ content }) => (
  <tr className="content-row">
    <td className="d-table-cell content-topic-cell">
      <span className="content-topic">{content.topic}</span>
    </td>
    <td className="d-none d-lg-table-cell">
      <span className="content-length">{content.length}</span>
    </td>
    <td className="d-none d-lg-table-cell">
      <code className="content-code">{content.content}</code>
    </td>
  </tr>
);

class AccountContentExisting extends Component {
  constructor(props) {
    super(props);

    this.fetchWPPages = this.fetchWPPages.bind(this);
    this.fetchWPLocations = this.fetchWPLocations.bind(this);
    this.fetchWPServiceAreas = this.fetchWPServiceAreas.bind(this);

    const { user } = this.props.auth0;

    const roleNamespace = "https://searchboss.app/role";
    const role = user[roleNamespace];
    let staticRules = "";
    let dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      const currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      excludedWords: "",
      content: [],
      pendingPages: [],
      wpPages: [],
      wpLocations: [],
      wpServiceAreas: [],
      authUser: "",
      seo: "",
      org: user["https://searchboss.app/org"],
      role,
      roles,
      staticRules,
      dynamicRules,
      isReady: false,
      loadingPages: true,
      loadingLocations: true,
      loadingServiceAreas: true,
    };
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:content")) {
      try {
        const config = await this.config();
        const response = await axios.get(
          `${process.env.REACT_APP_API}/account/content/${this.props.match.params.id}`,
          config,
        );

        this.setState({
          accountname: response.data.accountname,
          user: response.data.user,
          accounturl: response.data.accounturl,
          excludedWords: response.data.excludedWords,
          contentContext: response.data.contentContext,
          content: response.data.content,
          pendingPages: response.data.pendingPages,
          authUser: response.data.authUser,
          crawling: response.data.crawling,
          seo:
            response.data.pages[0].h1s.join("\r\n") +
            "\r\n" +
            response.data.pages[0].title,
        });

        document.title = `${this.state.accountname} - Existing Content Interface - searchboss`;

        // Fetch WordPress content
        this.fetchWPPages([], 0); // Initialize fetch with empty array and 0 offset
        this.fetchWPLocations([], 0);
        this.fetchWPServiceAreas([], 0);

        // Set up refresh interval for content
        this.timerID = setInterval(() => this.refreshContent(), 15000);
      } catch (error) {
        console.log("Error loading account content:", error);
        this.setState({ isReady: true });
      }
    } else {
      this.setState({ isReady: true });
    }
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  }

  renderCrawlingStatus() {
    return this.state.crawling ? (
      <div className="crawling-status">
        <span className="status-indicator"></span>
        <h4 className="crawl-text">Crawl in progress</h4>
      </div>
    ) : null;
  }

  contentList() {
    return this.state.content.map((currentContent) => (
      <Content content={currentContent} key={currentContent._id} />
    ));
  }

  contentListLength() {
    return this.state.content.length;
  }

  contentListMonth() {
    const contentList = this.state.content;
    const today = new Date();

    let month = String(today.getMonth() + 1);
    const year = String(today.getFullYear());

    if (month < 10) {
      month = `0${month}`;
    }

    const dateFilter = `${year}-${month}`;
    const monthPages = contentList.filter((item) =>
      item.createdAt.startsWith(dateFilter),
    );

    return monthPages.length;
  }

  async refreshContent() {
    try {
      const config = await this.config();
      const response = await axios.get(
        `${process.env.REACT_APP_API}/account/content/${this.props.match.params.id}`,
        config,
      );

      this.setState({
        content: response.data.content,
        pendingPages: response.data.pendingPages,
      });
    } catch (error) {
      console.log("Error refreshing content:", error);
    }
  }

  fetchWPPages(wpPagesArray, offset) {
    const wpPagesURL = `${this.state.accounturl}wp-json/wp/v2/pages?per_page=100&offset=${offset}`;

    axios
      .get(wpPagesURL)
      .then((res) => {
        const newPages = [...wpPagesArray, ...res.data];
        this.setState({
          wpPages: newPages,
          loadingPages: res.data.length === 100, // If exactly 100 items returned, might be more
        });

        if (res.data.length === 100) {
          // Check if there might be more pages
          console.log(
            `Checking for more pages, current total: ${newPages.length}`,
          );
          this.fetchWPPages(newPages, offset + 100);
        } else {
          console.log(`Complete, total pages: ${newPages.length}`);
          this.checkIfAllDataLoaded();
        }
      })
      .catch((err) => {
        console.error("Error fetching WordPress pages:", err);
        this.setState({
          loadingPages: false,
        });
        this.checkIfAllDataLoaded();
      });
  }

  fetchWPLocations(wpLocationsArray, offset) {
    const wpLocationsURL = `${this.state.accounturl}wp-json/wp/v2/location?per_page=100&offset=${offset}`;

    axios
      .get(wpLocationsURL)
      .then((res) => {
        const newLocations = [...wpLocationsArray, ...res.data];
        this.setState({
          wpLocations: newLocations,
          loadingLocations: res.data.length === 100,
        });

        if (res.data.length === 100) {
          console.log(
            `Checking for more location pages, current total: ${newLocations.length}`,
          );
          this.fetchWPLocations(newLocations, offset + 100);
        } else {
          console.log(`Complete, total location pages: ${newLocations.length}`);
          this.checkIfAllDataLoaded();
        }
      })
      .catch((err) => {
        console.error("Error fetching WordPress locations:", err);
        this.setState({ loadingLocations: false });
        this.checkIfAllDataLoaded();
      });
  }

  fetchWPServiceAreas(wpServiceAreasArray, offset) {
    const wpServiceAreasURL = `${this.state.accounturl}wp-json/wp/v2/service-area?per_page=100&offset=${offset}`;

    axios
      .get(wpServiceAreasURL)
      .then((res) => {
        const newServiceAreas = [...wpServiceAreasArray, ...res.data];
        this.setState({
          wpServiceAreas: newServiceAreas,
          loadingServiceAreas: res.data.length === 100,
        });

        if (res.data.length === 100) {
          console.log(
            `Checking for more service area pages, current total: ${newServiceAreas.length}`,
          );
          this.fetchWPServiceAreas(newServiceAreas, offset + 100);
        } else {
          console.log(
            `Complete, total service area pages: ${newServiceAreas.length}`,
          );
          this.checkIfAllDataLoaded();
        }
      })
      .catch((err) => {
        console.error("Error fetching WordPress service areas:", err);
        this.setState({ loadingServiceAreas: false });
        this.checkIfAllDataLoaded();
      });
  }

  checkIfAllDataLoaded() {
    if (
      !this.state.loadingPages &&
      !this.state.loadingLocations &&
      !this.state.loadingServiceAreas
    ) {
      this.setState({ isReady: true });
    }
  }

  wpPagesList(setFieldValue) {
    if (!this.state.wpPages || this.state.wpPages.length === 0) {
      return <p className="text-muted">No standard pages found</p>;
    }

    const sortedWpPages = this.state.wpPages.sort((a, b) => a.id - b.id);

    return (
      <div className="checkbox-container">
        {sortedWpPages.map((page) => (
          <div className="checkbox-item" key={page.id}>
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                id={`page-${page.id}`}
                name={`selectedPages.${page.id}`}
                className="custom-control-input"
              />
              <label
                className="custom-control-label"
                htmlFor={`page-${page.id}`}
              >
                <a
                  href={page.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="page-link"
                  title={page.title.rendered || `Page #${page.id}`}
                >
                  {page.title.rendered || `Page #${page.id}`}
                </a>
              </label>
            </div>
          </div>
        ))}
      </div>
    );
  }

  wpLocationsList(setFieldValue) {
    if (!this.state.wpLocations || this.state.wpLocations.length === 0) {
      return <p className="text-muted">No location pages found</p>;
    }

    const sortedWpLocations = this.state.wpLocations.sort(
      (a, b) => a.id - b.id,
    );

    return (
      <div className="checkbox-container">
        {sortedWpLocations.map((page) => (
          <div className="checkbox-item" key={page.id}>
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                id={`location-${page.id}`}
                name={`selectedLocations.${page.id}`}
                className="custom-control-input"
              />
              <label
                className="custom-control-label"
                htmlFor={`location-${page.id}`}
              >
                <a
                  href={page.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="page-link"
                  title={page.title.rendered || `Location #${page.id}`}
                >
                  {page.title.rendered || `Location #${page.id}`}
                </a>
              </label>
            </div>
          </div>
        ))}
      </div>
    );
  }

  wpServiceAreasList(setFieldValue) {
    if (!this.state.wpServiceAreas || this.state.wpServiceAreas.length === 0) {
      return <p className="text-muted">No service area pages found</p>;
    }

    const sortedWpServiceAreas = this.state.wpServiceAreas.sort(
      (a, b) => a.id - b.id,
    );

    return (
      <div className="checkbox-container">
        {sortedWpServiceAreas.map((page) => (
          <div className="checkbox-item" key={page.id}>
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                id={`service-area-${page.id}`}
                name={`selectedServiceAreas.${page.id}`}
                className="custom-control-input"
              />
              <label
                className="custom-control-label"
                htmlFor={`service-area-${page.id}`}
              >
                <a
                  href={page.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="page-link"
                  title={page.title.rendered || `Service Area #${page.id}`}
                >
                  {page.title.rendered || `Service Area #${page.id}`}
                </a>
              </label>
            </div>
          </div>
        ))}
      </div>
    );
  }

  selectAllPages(setFieldValue) {
    if (this.state.wpPages) {
      this.state.wpPages.forEach((page) => {
        setFieldValue(`selectedPages.${page.id}`, true);
      });
    }
  }

  deselectAllPages(setFieldValue) {
    if (this.state.wpPages) {
      this.state.wpPages.forEach((page) => {
        setFieldValue(`selectedPages.${page.id}`, false);
      });
    }
  }

  selectAllLocations(setFieldValue) {
    if (this.state.wpLocations) {
      this.state.wpLocations.forEach((page) => {
        setFieldValue(`selectedLocations.${page.id}`, true);
      });
    }
  }

  deselectAllLocations(setFieldValue) {
    if (this.state.wpLocations) {
      this.state.wpLocations.forEach((page) => {
        setFieldValue(`selectedLocations.${page.id}`, false);
      });
    }
  }

  selectAllServiceAreas(setFieldValue) {
    if (this.state.wpServiceAreas) {
      this.state.wpServiceAreas.forEach((page) => {
        setFieldValue(`selectedServiceAreas.${page.id}`, true);
      });
    }
  }

  deselectAllServiceAreas(setFieldValue) {
    if (this.state.wpServiceAreas) {
      this.state.wpServiceAreas.forEach((page) => {
        setFieldValue(`selectedServiceAreas.${page.id}`, false);
      });
    }
  }

  renderPendingPages() {
    if (this.state.pendingPages && this.state.pendingPages.length > 0) {
      return <PendingPages pendingPages={this.state.pendingPages} />;
    }
    return null;
  }

  showNotification(message, type = "success") {
    // For now, using alert, but could be replaced with a more attractive notification system
    alert(message);
  }

  renderAccountHeader() {
    return (
      <div className="account-header-container mb-4">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h1 className="account-title mb-2">{this.state.accountname}</h1>
                <a
                  href={this.state.accounturl}
                  className="account-url"
                  target="_blank"
                  rel="noreferrer"
                >
                  {this.state.accounturl}
                </a>
              </div>
              <div className="col-lg-6">
                <div className="account-stats text-lg-right">
                  {this.renderCrawlingStatus()}
                  <p className="user-info mb-2">
                    <strong>User:</strong> {this.state.user}
                  </p>
                  <div className="stats-container d-flex justify-content-lg-end">
                    <div className="stat-item mr-4">
                      <span className="stat-label">Total Pages:</span>
                      <span className="stat-value">
                        {this.contentListLength()}
                      </span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-label">Pages This Month:</span>
                      <span className="stat-value">
                        {this.contentListMonth()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContentExistingForm() {
    // Initialize Yup validation
    const orderContentSchema = Yup.object().shape({
      length: Yup.number().required(
        "Length is required as a numeric value stating the total number of words.",
      ),
      lists: Yup.string(),
      autoPush: Yup.string(),
      nameShortcode: Yup.string(),
      geoShortcode: Yup.string(),
      requestContext: Yup.string(),
      referenceUrl: Yup.string().url("Please enter a valid URL").nullable(),
      seoData: Yup.string(),
    });

    const initialValues = {
      selectedPages: {},
      selectedLocations: {},
      selectedServiceAreas: {},
      length: 750,
      lists: "Yes",
      autoPush: "Yes",
      nameShortcode: "Yes",
      geoShortcode: "Yes",
      requestContext: "",
      seoData: this.state.seo,
      referenceUrl: "",
    };

    return (
      <div className="content-form-container">
        <div className="card">
          <div className="card-header bg-primary text-white">
            <h3 className="card-title mb-0">
              Order Content for Existing Pages
            </h3>
          </div>
          <div className="card-body">
            <Formik
              initialValues={initialValues}
              validationSchema={orderContentSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const config = await this.config();
                  const response = await axios.post(
                    `${process.env.REACT_APP_API}/account/content/${this.props.match.params.id}/request/existing`,
                    values,
                    config,
                  );

                  // Update pending pages from response
                  if (response.data.pendingPages) {
                    this.setState({
                      pendingPages: response.data.pendingPages,
                    });
                  }

                  this.showNotification(
                    "Content procurement started. This might take a while.",
                  );
                } catch (err) {
                  this.showNotification(
                    `Error: ${JSON.stringify(err.response?.data || err.message)}`,
                    "error",
                  );
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {(formik) => {
                const {
                  errors,
                  touched,
                  isValid,
                  dirty,
                  setFieldValue,
                  isSubmitting,
                } = formik;
                return (
                  <Form>
                    {/* Standard Pages Section */}
                    <div className="form-section mb-4">
                      <div className="form-section-header">
                        <h4 className="mb-3">Standard Pages</h4>
                        <div className="form-section-actions mb-3">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm mr-2"
                            onClick={() => this.selectAllPages(setFieldValue)}
                          >
                            Select All
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => this.deselectAllPages(setFieldValue)}
                          >
                            Deselect All
                          </button>
                        </div>
                      </div>
                      <div className="pages-selection-container">
                        {this.wpPagesList(setFieldValue)}
                      </div>
                    </div>

                    {/* Location Pages Section */}
                    {
                      <div className="form-section mb-4">
                        <div className="form-section-header">
                          <h4 className="mb-3">Location Pages</h4>
                          <div className="form-section-actions mb-3">
                            <button
                              type="button"
                              className="btn btn-outline-primary btn-sm mr-2"
                              onClick={() =>
                                this.selectAllLocations(setFieldValue)
                              }
                            >
                              Select All
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-sm"
                              onClick={() =>
                                this.deselectAllLocations(setFieldValue)
                              }
                            >
                              Deselect All
                            </button>
                          </div>
                        </div>
                        <div className="pages-selection-container">
                          {this.wpLocationsList(setFieldValue)}
                        </div>
                      </div>
                    }

                    {/* Service Area Pages Section */}
                    {
                      <div className="form-section mb-4">
                        <div className="form-section-header">
                          <h4 className="mb-3">Service Area Pages</h4>
                          <div className="form-section-actions mb-3">
                            <button
                              type="button"
                              className="btn btn-outline-primary btn-sm mr-2"
                              onClick={() =>
                                this.selectAllServiceAreas(setFieldValue)
                              }
                            >
                              Select All
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-sm"
                              onClick={() =>
                                this.deselectAllServiceAreas(setFieldValue)
                              }
                            >
                              Deselect All
                            </button>
                          </div>
                        </div>
                        <div className="pages-selection-container">
                          {this.wpServiceAreasList(setFieldValue)}
                        </div>
                      </div>
                    }

                    <hr className="my-4" />

                    {/* Content Settings Section */}
                    <h4 className="mb-3">Content Settings</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="length">Length (word count)</label>
                          <Field
                            type="number"
                            max="1000"
                            min="5"
                            name="length"
                            id="length"
                            className={`form-control ${errors.length && touched.length ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            name="length"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="lists">
                            Include Lists in Content
                          </label>
                          <Field
                            as="select"
                            name="lists"
                            id="lists"
                            className={`form-control ${errors.lists && touched.lists ? "is-invalid" : ""}`}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Field>
                          <ErrorMessage
                            name="lists"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="autoPush">Auto Push to API</label>
                          <Field
                            as="select"
                            name="autoPush"
                            id="autoPush"
                            className={`form-control ${errors.autoPush && touched.autoPush ? "is-invalid" : ""}`}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Field>
                          <ErrorMessage
                            name="autoPush"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="nameShortcode">
                            Name Shortcode Replacement
                            <small>
                              {" "}
                              - Uses <b>[name]</b>
                            </small>
                          </label>
                          <Field
                            as="select"
                            name="nameShortcode"
                            id="nameShortcode"
                            className={`form-control ${errors.nameShortcode && touched.nameShortcode ? "is-invalid" : ""}`}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </Field>
                          <ErrorMessage
                            name="nameShortcode"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="geoShortcode">
                            Geo Shortcode Replacement
                            <small>
                              {" "}
                              - Uses <b>[geo] & [region]</b>
                            </small>
                          </label>
                          <Field
                            as="select"
                            name="geoShortcode"
                            id="geoShortcode"
                            className={`form-control ${errors.geoShortcode && touched.geoShortcode ? "is-invalid" : ""}`}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </Field>
                          <ErrorMessage
                            name="geoShortcode"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="requestContext">
                            Additional Context
                          </label>
                          <Field
                            as="textarea"
                            name="requestContext"
                            id="requestContext"
                            rows="4"
                            placeholder="Add context specific to this request in addition to the account level context"
                            className={`form-control ${errors.requestContext && touched.requestContext ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            name="requestContext"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="referenceUrl">Reference URL</label>
                          <Field
                            type="text"
                            name="referenceUrl"
                            id="referenceUrl"
                            placeholder="Accepts a properly formatted URL for content context"
                            className={`form-control ${errors.referenceUrl && touched.referenceUrl ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            name="referenceUrl"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="seoData">SEO Data</label>
                      <Field
                        as="textarea"
                        name="seoData"
                        id="seoData"
                        rows="3"
                        className={`form-control ${errors.seoData && touched.seoData ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        name="seoData"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="account-info-container p-3 bg-light mb-3 rounded">
                      <h5>Account Settings</h5>
                      <div className="row">
                        <div className="col-md-4">
                          <p>
                            <b>Excluded Words:</b>{" "}
                            <span className="text-muted">
                              {this.state.excludedWords || "None"}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-4">
                          <p>
                            <b>Content Context:</b>{" "}
                            <span className="text-muted">
                              {this.state.contentContext || "None"}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-4">
                          <p>
                            <b>API Auth User:</b>{" "}
                            <span className="text-muted">
                              {this.state.authUser || "None"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <small className="text-muted">
                        Define excluded words, content context & API auth
                        settings in the Account via the Edit panel
                      </small>
                    </div>

                    <div className="text-right">
                      <button
                        type="submit"
                        className={`btn btn-primary btn-lg ${isSubmitting || !isValid ? "disabled" : ""}`}
                        disabled={isSubmitting || !isValid}
                      >
                        {isSubmitting ? "Submitting..." : "Submit Order"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }

  renderContentTable() {
    return (
      <div className="content-table-container mt-4">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3 className="card-title mb-0">Existing Content</h3>
            <span className="badge badge-primary">
              {this.contentListLength()} items
            </span>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table table-hover content-table mb-0">
                <thead className="thead-light">
                  <tr>
                    <th className="d-table-cell">Topic</th>
                    <th className="d-none d-lg-table-cell">Length</th>
                    <th className="d-none d-lg-table-cell">Content</th>
                  </tr>
                </thead>
                <tbody>{this.contentList()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAccessDenied() {
    return (
      <div className="access-denied-container text-center">
        <div className="card">
          <div className="card-body">
            <h1 className="text-danger mb-3">Access Denied</h1>
            <h2 className="mb-4">
              This account level does not have permission to view account
              content pages.
            </h2>
            <Link to="/dashboard" className="btn btn-primary">
              Return to Dashboard
            </Link>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    return (
      <Can
        role={this.state.role}
        perform="accounts:content"
        yes={() => (
          <div className="account-content-existing-container">
            {this.renderAccountHeader()}
            {this.renderContentExistingForm()}
            {this.renderPendingPages()}
            {this.renderContentTable()}
          </div>
        )}
        no={() => this.renderAccessDenied()}
      />
    );
  }
}

export default withAuth0(AccountContentExisting);
