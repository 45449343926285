const PendingPages = ({ pendingPages }) => (
  <div className="pending-pages-container mt-4 mb-4">
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center bg-warning text-white">
        <h3 className="card-title mb-0">Pending Content</h3>
        <span className="badge badge-light">
          {pendingPages.length} in progress
        </span>
      </div>
      <div className="card-body p-0">
        {pendingPages.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-hover pending-table mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="d-table-cell">Topic</th>
                  <th className="d-table-cell">Requested</th>
                  <th className="d-table-cell text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {pendingPages.map((page, index) => (
                  <tr key={index} className="pending-row">
                    <td className="d-table-cell pending-topic-cell">
                      <span className="pending-topic">{page.topic}</span>
                    </td>
                    <td className="d-table-cell">
                      {new Date(page.createdAt).toLocaleString()}
                    </td>
                    <td className="d-table-cell text-center">
                      <div className="spinner-container">
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        >
                          <span className="sr-only">Generating...</span>
                        </div>
                        <span className="ml-2">Generating...</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center py-4">
            <p className="mb-0 text-muted">
              No pending content requests at this time.
            </p>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default PendingPages;
