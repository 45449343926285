import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

class EditAccount extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      crawling: "",
      startdate: "",
      status: "",
      boostlinks: "",
      socialLocationId: "",
      socialPostCount: 0,
      industry: "",
      contacts: "",
      excludedWords: "",
      contentContext: "",
      authUser: "",
      authKey: "",
      productAPI: "Blaze",
      productClientID: "",
      productAuth: "",
      users: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:edit")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/account/edit/' + this.props.match.params.id,  config)
        .then(response => {
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            startdate: response.data.startdate,
            status: response.data.status,
            boostlinks: response.data.boostlinks,
            industry: response.data.industry,
            socialLocationId: response.data.socialLocationId,
            socialPostCount: response.data.socialPostCount,
            contacts: response.data.contacts,
            authUser: response.data.authUser,
            authKey: response.data.authKey,
            excludedWords: response.data.excludedWords,
            contentContext: response.data.contentContext,
            info: response.data.info,
            crawlSchedule: response.data.crawlSchedule,
            productAPI: response.data.productAPI,
            productClientID: response.data.productClientID,
            productAuth: response.data.productAuth,
            crawling: response.data.crawling
          })
          document.title = "Edit Account - " + this.state.accounturl + " - searchboss";
        })
        .catch(err => console.log(err))

        axios.get(process.env.REACT_APP_API + '/user/email/', config)
          .then(response => {
            response.data.unshift({email: "Unassigned"});
            this.setState({
              users: response.data.map(user => user.email),
              isReady: true
            });
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        return null;
      }
    }

    render() {
      if (this.state.isReady === false) {
        return <Loading />;
      }

      const initialValues = {
        accountname: this.state.accountname,
        user: this.state.user,
        accounturl: this.state.accounturl,
        crawlSchedule: this.state.crawlSchedule,
        startdate: this.state.startdate,
        status: this.state.status,
        boostlinks: this.state.boostlinks,
        industry: this.state.industry,
        socialLocationId: this.state.socialLocationId,
        socialPostCount: this.state.socialPostCount,
        contacts: this.state.contacts,
        authUser: this.state.authUser,
        authKey: this.state.authKey,
        excludedWords: this.state.excludedWords,
        contentContext: this.state.contentContext,
        productAPI: this.state.productAPI,
        productAuth: this.state.productAuth,
        productClientID: this.state.productClientID,
        info: this.state.info
      };

      // initialize Yup validation
      const editAccountSchema = Yup.object().shape({
        accountname: Yup.string().required("Account name is required."),
        user: Yup.string().required("User is required."),
        accounturl: Yup.string().url("Must be a URL starting with http:// or https://").required("URL is required."),
        startdate: Yup.date(),
        status: Yup.string(),
        boostlinks: Yup.number(),
        socialLocationId: Yup.string(),
        socialPostCount: Yup.number(),
        contacts: Yup.string(),
        excludedWords: Yup.string(),
        contentContext: Yup.string().when('authUser', {
          is: authUser => !!authUser,
          then: Yup.string().required("Content context is required when Auth User is provided."),
          otherwise: Yup.string(),
        }),
        authUser: Yup.string(),
        authKey: Yup.string(),
        productAPI: Yup.string(),
        productAuth: Yup.string(),
        productClientID: Yup.string(),
        info: Yup.string()
      });

      return (
        <Can
          role={this.state.role}
          perform="accounts:edit"
          yes={() => (
            <div className="edit-account-container">
              <div className="card shadow-sm mb-4">
                <div className="card-header py-3" style={{ background: 'linear-gradient(90deg, #2c3e50, #1a2530)' }}>
                  <h3 className="card-title mb-0 text-white d-flex align-items-center">
                    <i className="bi bi-pencil-square me-2"></i>
                    Edit Account
                  </h3>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={editAccountSchema}
                    enableReinitialize={true}
                    onSubmit={async (values) => {
                      if ( !(values.accounturl.endsWith("/")) ) {
                        values.accounturl = values.accounturl + "/";
                      }

                      const config = await this.config();

                      if (this.state.crawling === false) {
                        try {
                          let res = await axios.post(process.env.REACT_APP_API + '/account/update/' + this.props.match.params.id, values, config);
                          alert("Account updated!");
                          setTimeout(() => { window.location = '/account/' + this.props.match.params.id + '/home/'}, 500);
                        } catch (err) {
                          alert(err.response.data);
                        }
                      } else {
                        alert("Crawl in progress, account cannot be updated while account is crawling.")
                      }
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isValid, dirty } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="section-card mb-4">
                                <div className="section-header d-flex align-items-center mb-3">
                                  <i className="bi bi-building me-2 text-primary"></i>
                                  <h5 className="mb-0">Account Information</h5>
                                </div>

                                <div className="row g-3">
                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="accountname" className="form-label">Account Name</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-person-badge"></i>
                                        </span>
                                        <Field
                                          type="text"
                                          name="accountname"
                                          id="accountname"
                                          className={`form-control ${errors.accountname && touched.accountname ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="accountname" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="user" className="form-label">User</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-person"></i>
                                        </span>
                                        <Field
                                          as="select"
                                          name="user"
                                          id="user"
                                          className={`form-select ${errors.user && touched.user ? "is-invalid" : ""}`}
                                        >
                                          {
                                            this.state.users.map(function(user) {
                                              return <option
                                                key={user}
                                                value={user}>{user}
                                                </option>;
                                            })
                                          }
                                        </Field>
                                      </div>
                                      <ErrorMessage name="user" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="accounturl" className="form-label">URL</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-globe"></i>
                                        </span>
                                        <Field
                                          type="string"
                                          name="accounturl"
                                          id="accounturl"
                                          className={`form-control ${errors.accounturl && touched.accounturl ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="accounturl" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="startdate" className="form-label">Start Date</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-calendar-date"></i>
                                        </span>
                                        <Field
                                          type="string"
                                          name="startdate"
                                          id="startdate"
                                          className={`form-control ${errors.startdate && touched.startdate ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="startdate" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="status" className="form-label">Status</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-toggle-on"></i>
                                        </span>
                                        <Field
                                          as="select"
                                          name="status"
                                          id="status"
                                          className={`form-select ${errors.status && touched.status ? "is-invalid" : ""}`}
                                        >
                                          <option value="Active">Active</option>
                                          <option value="Boost">Boost</option>
                                          <option value="Content">Content</option>
                                          <option value="Sales Prospect">Sales Prospect</option>
                                          <option value="On Hold">On Hold</option>
                                          <option value="Canceled">Canceled</option>
                                        </Field>
                                      </div>
                                      <ErrorMessage name="status" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <Can
                                    role={this.state.role}
                                    perform="accounts:boost"
                                    yes={() => (
                                      <>
                                        <div className="col-md-6">
                                          <div className="form-group mb-3">
                                            <label htmlFor="boostlinks" className="form-label">Boost Links Per Month</label>
                                            <div className="input-group">
                                              <span className="input-group-text">
                                                <i className="bi bi-link-45deg"></i>
                                              </span>
                                              <Field
                                                type="string"
                                                name="boostlinks"
                                                id="boostlinks"
                                                className={`form-control ${errors.boostlinks && touched.boostlinks ? "is-invalid" : ""}`}
                                              />
                                            </div>
                                            <ErrorMessage name="boostlinks" component="div" className="invalid-feedback d-block" />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group mb-3">
                                            <label htmlFor="industry" className="form-label">Industry</label>
                                            <div className="input-group">
                                              <span className="input-group-text">
                                                <i className="bi bi-briefcase"></i>
                                              </span>
                                              <Field
                                                as="select"
                                                name="industry"
                                                id="industry"
                                                className={`form-select ${errors.industry && touched.industry ? "is-invalid" : ""}`}
                                              >
                                                <option value="Cannabis">Cannabis</option>
                                                <option value="Home Services">Home Services</option>
                                                <option value="Other">Other</option>
                                              </Field>
                                            </div>
                                            <ErrorMessage name="industry" component="div" className="invalid-feedback d-block" />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group mb-3">
                                            <label htmlFor="socialLocationId" className="form-label">GBP Location ID</label>
                                            <div className="input-group">
                                              <span className="input-group-text">
                                                <i className="bi bi-geo-alt"></i>
                                              </span>
                                              <Field
                                                type="string"
                                                name="socialLocationId"
                                                id="socialLocationId"
                                                className={`form-control ${errors.socialLocationId && touched.socialLocationId ? "is-invalid" : ""}`}
                                              />
                                            </div>
                                            <ErrorMessage name="socialLocationId" component="div" className="invalid-feedback d-block" />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group mb-3">
                                            <label htmlFor="socialPostCount" className="form-label">GBP Monthly Posts</label>
                                            <div className="input-group">
                                              <span className="input-group-text">
                                                <i className="bi bi-instagram"></i>
                                              </span>
                                              <Field
                                                type="number"
                                                name="socialPostCount"
                                                id="socialPostCount"
                                                className={`form-control ${errors.socialPostCount && touched.socialPostCount ? "is-invalid" : ""}`}
                                              />
                                            </div>
                                            <ErrorMessage name="socialPostCount" component="div" className="invalid-feedback d-block" />
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    no={() => (null)}
                                  />

                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="contacts" className="form-label">Contacts</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-people"></i>
                                        </span>
                                        <Field
                                          type="string"
                                          name="contacts"
                                          id="contacts"
                                          className={`form-control ${errors.contacts && touched.contacts ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="contacts" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="info" className="form-label">Notes</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-sticky"></i>
                                        </span>
                                        <Field
                                          component="textarea"
                                          name="info"
                                          id="info"
                                          rows="2"
                                          placeholder="Not for content creation, just for your own personal notes."
                                          className={`form-control ${errors.info && touched.info ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="info" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="section-card mb-4">
                                <div className="section-header d-flex align-items-center mb-3">
                                  <i className="bi bi-file-earmark-text me-2 text-primary"></i>
                                  <h5 className="mb-0">Content Information</h5>
                                </div>

                                <div className="row g-3">
                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="authUser" className="form-label">API Username</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-person-badge"></i>
                                        </span>
                                        <Field
                                          type="string"
                                          name="authUser"
                                          id="authUser"
                                          placeholder="API username for content pushing. Enter Wordpress username, not the API key name."
                                          className={`form-control ${errors.authUser && touched.authUser ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="authUser" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="authKey" className="form-label">API Key</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-key"></i>
                                        </span>
                                        <Field
                                          type="string"
                                          name="authKey"
                                          id="authKey"
                                          placeholder="API key for content pushing. Install key unencrypted."
                                          className={`form-control ${errors.authKey && touched.authKey ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="authKey" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="excludedWords" className="form-label">Excluded Words</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-x-circle"></i>
                                        </span>
                                        <Field
                                          type="string"
                                          name="excludedWords"
                                          id="excludedWords"
                                          placeholder="Add a comma separated list (ie: Word 1, Word 2, Word 3) of words that should be excluded from content or boost requests."
                                          className={`form-control ${errors.excludedWords && touched.excludedWords ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="excludedWords" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="contentContext" className="form-label">Content Context</label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="bi bi-card-text"></i>
                                        </span>
                                        <Field
                                          component="textarea"
                                          name="contentContext"
                                          id="contentContext"
                                          rows="5"
                                          placeholder="Add any important business context (ie: slogans, year founded, certifications or other things to highlight). This field can accept a lot of information - even paragraphs. Load it up!"
                                          className={`form-control ${errors.contentContext && touched.contentContext ? "is-invalid" : ""}`}
                                        />
                                      </div>
                                      <ErrorMessage name="contentContext" component="div" className="invalid-feedback d-block" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Can
                                role={this.state.role}
                                perform="accounts:productOpt"
                                yes={() => (
                                  <div className="section-card mb-4">
                                    <div className="section-header d-flex align-items-center mb-3">
                                      <i className="bi bi-box-seam me-2 text-primary"></i>
                                      <h5 className="mb-0">Product Optimization Settings</h5>
                                    </div>

                                    <div className="row g-3">
                                      <div className="col-md-4">
                                        <div className="form-group mb-3">
                                          <label htmlFor="productAPI" className="form-label">Product API Provider</label>
                                          <div className="input-group">
                                            <span className="input-group-text">
                                              <i className="bi bi-cloud"></i>
                                            </span>
                                            <Field
                                              as="select"
                                              name="productAPI"
                                              id="productAPI"
                                              className={`form-select ${errors.productAPI && touched.productAPI ? "is-invalid" : ""}`}
                                            >
                                              <option value="Blaze">Blaze</option>
                                              <option value="Jane">Jane</option>
                                              <option value="Range">Range</option>
                                            </Field>
                                          </div>
                                          <ErrorMessage name="productAPI" component="div" className="invalid-feedback d-block" />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="form-group mb-3">
                                          <label htmlFor="productClientID" className="form-label">Product Opt Client ID</label>
                                          <div className="input-group">
                                            <span className="input-group-text">
                                              <i className="bi bi-person-badge"></i>
                                            </span>
                                            <Field
                                              type="string"
                                              name="productClientID"
                                              id="productClientID"
                                              placeholder="Leave blank for Blaze API connections, use Retailer ID for Range"
                                              className={`form-control ${errors.productClientID && touched.productClientID ? "is-invalid" : ""}`}
                                            />
                                          </div>
                                          <ErrorMessage name="productClientID" component="div" className="invalid-feedback d-block" />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="form-group mb-3">
                                          <label htmlFor="productAuth" className="form-label">Product Opt Auth Key</label>
                                          <div className="input-group">
                                            <span className="input-group-text">
                                              <i className="bi bi-key"></i>
                                            </span>
                                            <Field
                                              type="string"
                                              name="productAuth"
                                              id="productAuth"
                                              placeholder="API key or auth code for product optimization connection"
                                              className={`form-control ${errors.productAuth && touched.productAuth ? "is-invalid" : ""}`}
                                            />
                                          </div>
                                          <ErrorMessage name="productAuth" component="div" className="invalid-feedback d-block" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                no={() => (null)}
                              />

                              <div className="text-end mt-4">
                                <button
                                  type="submit"
                                  className={`btn ${!(dirty && isValid) ? "btn-secondary" : "btn-primary"} px-4 py-2`}
                                  disabled={!(dirty && isValid)}
                                >
                                  <i className="bi bi-check-circle me-2"></i>
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          )}
          no={() => (
            <div className="access-denied-container text-center p-5">
              <div className="card shadow border-0">
                <div className="card-body p-5">
                  <i className="bi bi-shield-lock text-danger" style={{ fontSize: '4rem' }}></i>
                  <h2 className="mt-3 mb-2">Access Denied</h2>
                  <p className="text-muted mb-4">Your access level does not permit editing accounts. Please contact your administrator for assistance.</p>
                  <a href="/" className="btn btn-primary">
                    <i className="bi bi-arrow-left me-2"></i>
                    Return to Dashboard
                  </a>
                </div>
              </div>
            </div>
          )}
        />
      )
    }
  }

export default withAuth0(EditAccount);
