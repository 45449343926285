import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

const Account = (props) => (
  <tr className="account-row align-middle">
    <td className="account-info">
      <Link
        to={"/account/" + props.account._id + "/home/"}
        className="account-name fw-bold text-decoration-none"
      >
        {props.account.accountname}
      </Link>
      <small className="account-url text-muted d-block mt-1">
        <i className="bi bi-globe2 me-1"></i>
        {props.account.accounturl}
      </small>
      <Can
        role={props.role}
        perform="accounts:boost"
        yes={() => (
          <div className="boost-stat d-block d-lg-none mt-2">
            <small className="badge bg-light text-dark p-2">
              <i className="bi bi-graph-up me-1"></i>
              <span className="fw-bold">Boost:</span> {props.monthlyBlogCount} /{" "}
              {props.blogCount}
              <span className="ms-1 badge">{props.completion}</span>
            </small>
          </div>
        )}
        no={() => null}
      />
    </td>
    <td className="d-none d-lg-table-cell user-column">{props.account.user}</td>
    <td className="d-none d-lg-table-cell last-crawl-column">
      {props.account.lastCrawl === "Crawl in progress." ||
      props.account.lastCrawl === "Crawl started!" ? (
        <span className="badge bg-info text-white p-2">
          <i className="bi bi-arrow-repeat me-1"></i>
          {props.account.lastCrawl}
        </span>
      ) : (
        <span>{props.account.lastCrawl}</span>
      )}
    </td>
    <Can
      role={props.role}
      perform="accounts:boost"
      yes={() => (
        <td className="d-none d-lg-table-cell boost-column">
          <div className="boost-count">
            <span className="badge bg-primary p-2 text-white">
              {props.monthlyBlogCount} / {props.blogCount}
            </span>
          </div>
          <small className="completion-badge mt-1 d-block">
            {props.completion}
          </small>
        </td>
      )}
      no={() => null}
    />
    <td className="action-buttons">
      <div className="d-flex flex-wrap gap-2">
        <Can
          role={props.role}
          perform="accounts:edit"
          yes={() => (
            <Link
              className="btn btn-sm btn-outline-primary m-1"
              to={"/account/" + props.account._id + "/edit/"}
            >
              <i className="bi bi-pencil-square me-1"></i>Edit
            </Link>
          )}
          no={() => null}
        />
        <Can
          role={props.role}
          perform="accounts:content"
          yes={() => (
            <Link
              className="btn btn-sm btn-outline-primary m-1"
              to={props.account._id + "/content/"}
            >
              <i className="bi bi-file-text me-1"></i>Content
            </Link>
          )}
          no={() => null}
        />
        <Can
          role={props.role}
          perform="accounts:content"
          yes={() => (
            <Link
              className="btn btn-sm btn-outline-primary m-1"
              to={props.account._id + "/content-existing/"}
            >
              <i className="bi bi-arrow-repeat me-1"></i>Rewrites
            </Link>
          )}
          no={() => null}
        />
        <Can
          role={props.role}
          perform="accounts:boost"
          yes={() => (
            <Link
              className="btn btn-sm btn-outline-primary m-1"
              to={props.account._id + "/boost/"}
            >
              <i className="bi bi-graph-up me-1"></i>Boost
            </Link>
          )}
          no={() => null}
        />
        <Can
          role={props.role}
          perform="accounts:crawl"
          yes={() => (
            <button
              className="btn btn-sm btn-outline-primary m-1"
              onClick={() => {
                props.crawlAccount(props.account._id);
              }}
            >
              <i className="bi bi-search me-1"></i>Crawl
            </button>
          )}
          no={() => null}
        />
      </div>
    </td>
  </tr>
);

class AccountList extends Component {
  constructor(props) {
    super(props);

    // Get initial search value from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const initialSearch = urlParams.get("q") || "";

    const { user } = this.props.auth0;
    const roleNamespace = "https://searchboss.app/role";
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    }

    this.state = {
      accounts: [],
      role: role,
      user: user.email,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false,
      limit: 50,
      skip: 0,
      loadMoreButtonClass: "btn btn-primary",
      search: initialSearch,
    };

    this.deleteAccount = this.deleteAccount.bind(this);
    this.crawlAccount = this.crawlAccount.bind(this);
    this.search = this.search.bind(this);
  }

  // Core functionality remains the same
  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    return {
      headers: { Authorization: "Bearer " + token },
    };
  }

  async componentDidMount() {
    document.title = "Accounts - searchboss";

    // Check for search parameter and perform search if present
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get("q");

    if (searchQuery) {
      await this.performSearch(searchQuery);
    } else {
      await this.loadAccounts();
    }
  }

  async loadAccounts() {
    if (this.state.staticRules.includes("accounts:view")) {
      const config = await this.config();
      var limit = this.state.limit;
      var skip = this.state.skip;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/account/?limit=${limit}&skip=${skip}`,
          config,
        );

        this.setState({
          accounts: response.data,
          isReady: true,
          loadMoreButtonClass:
            response.data.length < limit ? "d-none" : "btn btn-primary",
        });

        this.updateCrawlingStatus();
      } catch (error) {
        console.log(error);
        this.setState({ isReady: true });
      }
    } else {
      alert(
        "This access level does not have permission to view accounts. Please contact your administrator.",
      );
    }
  }

  async loadMoreAccounts() {
    if (this.state.staticRules.includes("accounts:view")) {
      const config = await this.config();
      var limit = this.state.limit;
      var skip = this.state.skip + 50;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/account/?limit=${limit}&skip=${skip}`,
          config,
        );

        const accounts = this.state.accounts.concat(response.data);

        this.setState({
          accounts: accounts,
          isReady: true,
          skip: skip,
          loadMoreButtonClass:
            response.data.length < limit
              ? "d-none"
              : "btn btn-outline-primary px-4",
        });

        this.updateCrawlingStatus();
      } catch (error) {
        console.log(error);
        alert(error);
      }
    } else {
      alert(
        "This access level does not have permission to view accounts. Please contact your administrator.",
      );
    }
  }

  LoadMoreAccountsButton() {
    return (
      <button
        className={this.state.loadMoreButtonClass}
        onClick={() => this.loadMoreAccounts()}
      >
        <i className="bi bi-plus-circle me-2"></i>
        Load More Accounts
      </button>
    );
  }

  async performSearch(searchQuery) {
    if (!this.state.staticRules.includes("accounts:view")) {
      alert(
        "This access level does not have permission to view accounts. Please contact your administrator.",
      );
      return;
    }

    this.setState({ isReady: false });

    try {
      const config = await this.config();
      const response = await axios.get(
        `${process.env.REACT_APP_API}/account/search?search=${searchQuery}`,
        config,
      );

      this.setState({
        accounts: response.data,
        loadMoreButtonClass: "d-none",
        isReady: true,
      });

      this.updateCrawlingStatus();

      // Update URL without reloading the page
      const newUrl = searchQuery
        ? `${window.location.pathname}?q=${encodeURIComponent(searchQuery)}`
        : window.location.pathname;

      window.history.pushState({ search: searchQuery }, "", newUrl);
    } catch (error) {
      console.log(error);
      alert(error);
      this.setState({ isReady: true });
    }
  }

  updateCrawlingStatus() {
    let accounts = [...this.state.accounts];
    accounts.forEach((account) => {
      if (account.crawling === true) {
        account.lastCrawl = "Crawl in progress.";
      }
    });
    this.setState({ accounts });
  }

  async search(event) {
    event.preventDefault();
    const searchQuery = this.state.search.trim();
    await this.performSearch(searchQuery);
  }

  async deleteAccount(id) {
    if (this.state.staticRules.includes("accounts:delete")) {
      const config = await this.config();
      const data = null;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/account/delete/${id}`,
          data,
          config,
        );

        console.log(response.data);
        if (response.data.crawling === false) {
          this.setState({
            accounts: this.state.accounts.filter((el) => el._id !== id),
          });
        }
      } catch (err) {
        alert(err.response.data.message);
      }
    } else {
      alert(
        "This access level does not have permission to delete accounts. Please contact your administrator.",
      );
    }
  }

  async crawlAccount(id) {
    if (this.state.staticRules.includes("accounts:crawl")) {
      const config = await this.config();
      const data = null;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/account/${id}/crawling/`,
          config,
        );

        const date = new Date();
        const dateString = date.toDateString();

        if (response.data.crawling === false) {
          if (response.data.lastCrawl === dateString) {
            alert("On-demand crawl already completed today.");
          } else {
            await axios.post(
              `${process.env.REACT_APP_API}/account/crawl/${id}`,
              data,
              config,
            );
            console.log("Crawl started!");

            let accounts = [...this.state.accounts];
            let index = accounts.findIndex((el) => el._id === id);
            let account = { ...accounts[index] };
            account.lastCrawl = "Crawl started!";
            accounts[index] = account;
            this.setState({ accounts });
          }
        } else {
          alert("Crawl already in progress.");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert(
        "This access level does not have permission to crawl accounts. Please contact your administrator.",
      );
    }
  }

  boostTotal() {
    if (this.state.staticRules.includes("accounts:boost")) {
      let result = this.state.accounts.map((a) => a.boostlinks);
      return result.reduce((partialSum, a) => partialSum + a, 0);
    }
  }

  publishedBoostTotal() {
    if (this.state.staticRules.includes("accounts:boost")) {
      const accountsList = this.state.accounts;
      const today = new Date();

      var month = String(today.getMonth() + 1);
      var year = String(today.getFullYear());

      if (month < 10) {
        month = "0" + month;
      }

      let dateFilter = year + "-" + month;
      let publishedLinks = 0;

      accountsList.forEach((currentaccount) => {
        var thisMonth = currentaccount.boost.filter((blog) =>
          blog.createdAt.startsWith(dateFilter),
        );
        publishedLinks += thisMonth.length;
      });

      return publishedLinks;
    }
  }

  accountList(role) {
    const accountsList = this.state.accounts;
    const today = new Date();

    var month = String(today.getMonth() + 1);
    var year = String(today.getFullYear());

    if (month < 10) {
      month = "0" + month;
    }

    let dateFilter = year + "-" + month;

    return accountsList.map((currentaccount) => {
      let blogCount = 0;
      let monthlyBlogCount = 0;
      let completion = "N/A";

      if (this.state.staticRules.includes("accounts:boost")) {
        blogCount = currentaccount.boostlinks;
        const monthlyBlogs = currentaccount.boost.filter((blog) =>
          blog.createdAt.startsWith(dateFilter),
        );
        monthlyBlogCount = monthlyBlogs.length;

        const percentage = (monthlyBlogCount / blogCount) * 100;
        completion = Math.round(percentage) + "%";

        if (completion === "NaN%" || completion === "Infinity%") {
          completion = "N/A";
        }
      }

      return (
        <Account
          account={currentaccount}
          deleteAccount={this.deleteAccount}
          crawlAccount={this.crawlAccount}
          blogCount={blogCount}
          monthlyBlogCount={monthlyBlogCount}
          completion={completion}
          role={role}
          key={currentaccount._id}
        />
      );
    });
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    return (
      <div className="accounts-container">
        <div className="header-section card mb-4 shadow-sm">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <h3 className="card-title mb-0 d-flex align-items-center">
                  <i className="bi bi-building me-2"></i>
                  Accounts
                </h3>
              </div>
              <div className="col-lg-5">
                <Can
                  role={this.state.role}
                  perform="accounts:boost"
                  yes={() => (
                    <div className="stats-container mb-3">
                      <div className="card border-0 bg-light">
                        <div className="card-body py-2">
                          <div className="d-flex justify-content-between">
                            <div className="stat-item">
                              <small className="text-muted">
                                Total Monthly Links
                              </small>
                              <div className="h5 mb-0">{this.boostTotal()}</div>
                            </div>
                            <div className="stat-item">
                              <small className="text-muted">
                                Published This Month
                              </small>
                              <div className="h5 mb-0">
                                {this.publishedBoostTotal()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  no={() => null}
                />
                <form
                  className="form-group search-bar"
                  onSubmit={(a) => this.search(a)}
                >
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search for account name"
                      value={this.state.search}
                      onChange={(event) =>
                        this.setState({ search: event.target.value })
                      }
                    />
                    <button type="submit" className="btn btn-primary">
                      <i className="bi bi-search">go!</i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="table-container card shadow-sm">
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table table-hover mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Account Name</th>
                    <th className="d-none d-lg-table-cell">User</th>
                    <th className="d-none d-lg-table-cell">Last Crawl</th>
                    <Can
                      role={this.state.role}
                      perform="accounts:boost"
                      yes={() => (
                        <th className="d-none d-lg-table-cell">
                          Boost{" "}
                          <small className="text-muted d-block">
                            Month / Demand
                          </small>
                        </th>
                      )}
                      no={() => null}
                    />
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{this.accountList(this.state.role)}</tbody>
              </table>
            </div>
          </div>
          <div className="card-footer text-center py-3">
            {this.LoadMoreAccountsButton()}
          </div>
        </div>
      </div>
    );
  }
}

export default withAuth0(AccountList);
