import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";
import roles from "../rbac-roles";
import { FieldArray } from "formik";
import PendingPages from "../components/pending-pages";

// Extracted Content component with improved styling
const Content = ({ content, pushPage, pushPost, role }) => (
  <tr className="content-row">
    <td className="d-table-cell content-topic-cell">
      <span className="content-topic">{content.topic}</span>
    </td>
    <td className="d-none d-lg-table-cell">
      <span className="content-length">{content.length}</span>
    </td>
    <td className="d-none d-lg-table-cell">
      <code className="content-code">{content.content}</code>
    </td>
    <td className="d-table-cell table-buttons">
      <div className="button-container">
        <Can
          role={role}
          perform="accounts:content"
          yes={() => (
            <button
              className="btn btn-primary mr-2 push-button"
              onClick={() => pushPage(content._id)}
            >
              Page
            </button>
          )}
          no={() => null}
        />
        <Can
          role={role}
          perform="accounts:content"
          yes={() => (
            <button
              className="btn btn-outline-primary push-button"
              onClick={() => pushPost(content._id)}
            >
              Post
            </button>
          )}
          no={() => null}
        />
      </div>
    </td>
  </tr>
);

class AccountContent extends Component {
  constructor(props) {
    super(props);

    this.pushPage = this.pushPage.bind(this);
    this.pushPost = this.pushPost.bind(this);

    const { user } = this.props.auth0;

    const roleNamespace = "https://searchboss.app/role";
    const role = user[roleNamespace];
    let staticRules = "";
    let dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      const currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      excludedWords: "",
      content: [],
      pendingPages: [],
      authUser: "",
      locationCPT: false,
      serviceAreaCPT: false,
      seo: "",
      org: user["https://searchboss.app/org"],
      role,
      roles,
      staticRules,
      dynamicRules,
      isReady: false,
    };
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:content")) {
      try {
        const config = await this.config();
        const response = await axios.get(
          `${process.env.REACT_APP_API}/account/content/${this.props.match.params.id}`,
          config,
        );

        this.setState({
          accountname: response.data.accountname,
          user: response.data.user,
          accounturl: response.data.accounturl,
          excludedWords: response.data.excludedWords,
          contentContext: response.data.contentContext,
          content: response.data.content,
          pendingPages: response.data.pendingPages,
          authUser: response.data.authUser,
          crawling: response.data.crawling,
          seo:
            response.data.pages[0].h1s.join("\r\n") +
            "\r\n" +
            response.data.pages[0].title,
          isReady: true,
        });

        document.title = `${this.state.accountname} - Content Interface - searchboss`;

        // Check for custom post types
        this.checkCustomPostTypes();

        // Set up refresh interval
        this.timerID = setInterval(() => this.refreshContent(), 15000);
      } catch (error) {
        console.log("Error loading account content:", error);
      }
    }
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  }

  async checkCustomPostTypes() {
    try {
      const url = new URL(this.state.accounturl);
      const baseUrl = url.origin;

      // Check for location CPT
      try {
        await axios.get(`${baseUrl}/wp-json/wp/v2/location`);
        this.setState({ locationCPT: true });
      } catch (err) {
        // Location CPT not available
      }

      // Check for service area CPT
      try {
        await axios.get(`${baseUrl}/wp-json/wp/v2/service-area`);
        this.setState({ serviceAreaCPT: true });
      } catch (err) {
        // Service area CPT not available
      }
    } catch (err) {
      console.log("Error checking custom post types:", err);
    }
  }

  renderCrawlingStatus() {
    return this.state.crawling ? (
      <div className="crawling-status">
        <span className="status-indicator"></span>
        <h4 className="crawl-text">Crawl in progress</h4>
      </div>
    ) : null;
  }

  contentList(role) {
    return this.state.content.map((currentContent) => (
      <Content
        content={currentContent}
        pushPage={this.pushPage}
        pushPost={this.pushPost}
        role={role}
        key={currentContent._id}
      />
    ));
  }

  contentListLength() {
    return this.state.content.length;
  }

  contentListMonth() {
    const contentList = this.state.content;
    const today = new Date();

    let month = String(today.getMonth() + 1);
    const year = String(today.getFullYear());

    if (month < 10) {
      month = `0${month}`;
    }

    const dateFilter = `${year}-${month}`;
    const monthPages = contentList.filter((item) =>
      item.createdAt.startsWith(dateFilter),
    );

    return monthPages.length;
  }

  async refreshContent() {
    try {
      const config = await this.config();
      const response = await axios.get(
        `${process.env.REACT_APP_API}/account/content/${this.props.match.params.id}`,
        config,
      );

      this.setState({
        content: response.data.content,
        pendingPages: response.data.pendingPages,
      });
    } catch (error) {
      console.log("Error refreshing content:", error);
    }
  }

  async pushPage(pageId) {
    if (this.state.staticRules.includes("accounts:content")) {
      try {
        const config = await this.config();
        await axios.post(
          `${process.env.REACT_APP_API}/account/content/${this.props.match.params.id}/push/${pageId}/page`,
          null,
          config,
        );

        this.showNotification(`Pushed to ${this.state.accounturl} as a draft!`);
      } catch (err) {
        this.showNotification(`Error: ${err.message}`, "error");
      }
    }
  }

  async pushPost(postId) {
    if (this.state.staticRules.includes("accounts:content")) {
      try {
        const config = await this.config();
        await axios.post(
          `${process.env.REACT_APP_API}/account/content/${this.props.match.params.id}/push/${postId}/post`,
          null,
          config,
        );

        this.showNotification(`Pushed to ${this.state.accounturl} as a draft!`);
      } catch (err) {
        this.showNotification(`Error: ${err.message}`, "error");
      }
    }
  }

  showNotification(message, type = "success") {
    // For now, using alert, but could be replaced with a more attractive notification system
    alert(message);
  }

  locationCPTOption() {
    return this.state.locationCPT ? (
      <option key="Location" value="Location">
        Location
      </option>
    ) : null;
  }

  serviceAreaCPTOption() {
    return this.state.serviceAreaCPT ? (
      <option key="service-area" value="service-area">
        Service Area
      </option>
    ) : null;
  }

  renderPendingPages() {
    if (this.state.pendingPages && this.state.pendingPages.length > 0) {
      return <PendingPages pendingPages={this.state.pendingPages} />;
    }
    return null;
  }

  renderContentForm() {
    // Initialize Yup validation
    const orderContentSchema = Yup.object().shape({
      topics: Yup.string(),
      length: Yup.number().required(
        "Length is required as a numeric value stating the total number of words.",
      ),
      lists: Yup.string(),
      autoPush: Yup.string(),
      pushType: Yup.string(),
      nameShortcode: Yup.string(),
      geoShortcode: Yup.string(),
      requestContext: Yup.string(),
      referenceUrl: Yup.string().url("Please enter a valid URL").nullable(),
    });

    const initialValues = {
      topics: [],
      length: 750,
      lists: "Yes",
      autoPush: "Yes",
      pushType: "Page",
      nameShortcode: "Yes",
      geoShortcode: "Yes",
      requestContext: "",
      seoData: this.state.seo,
      referenceUrl: "",
    };

    return (
      <div className="content-form-container">
        <div className="card">
          <div className="card-header bg-primary text-white">
            <h3 className="card-title mb-0">Order Content</h3>
          </div>
          <div className="card-body">
            <Formik
              initialValues={initialValues}
              validationSchema={orderContentSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                this.setState({ isSubmitting: true });
                try {
                  const config = await this.config();
                  const response = await axios.post(
                    `${process.env.REACT_APP_API}/account/content/${this.props.match.params.id}/request/`,
                    values,
                    config,
                  );

                  // Update pending pages from response
                  if (response.data.pendingPages) {
                    this.setState({
                      pendingPages: response.data.pendingPages,
                    });
                  }

                  this.showNotification(
                    "Content procurement started. This might take a while.",
                  );

                  // Reset form but keep some values
                  resetForm({
                    values: {
                      ...initialValues,
                      length: values.length,
                      lists: values.lists,
                      autoPush: values.autoPush,
                      pushType: values.pushType,
                      nameShortcode: values.nameShortcode,
                      geoShortcode: values.geoShortcode,
                      seoData: values.seoData,
                    },
                  });
                } catch (err) {
                  this.showNotification(
                    `Error: ${JSON.stringify(err.response?.data || err.message)}`,
                    "error",
                  );
                } finally {
                  setSubmitting(false);
                  this.setState({ isSubmitting: false });
                }
              }}
            >
              {(formik) => {
                const { errors, touched, isValid, dirty } = formik;
                return (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="topics">Topics</label>
                          <FieldArray name="topics">
                            {({ remove, push, form }) => (
                              <div>
                                <div className="input-group mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter a topic and press Enter. Multiple topics can be entered like 'Topic 1 || Topic 2 || Topic 3'"
                                    value={form.values.newTopic || ""}
                                    onChange={(e) => {
                                      form.setFieldValue(
                                        "newTopic",
                                        e.target.value,
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        const topic =
                                          form.values.newTopic.trim();

                                        if (topic) {
                                          // Check if the input contains the old delimiter format
                                          if (topic.includes("||")) {
                                            // Split by the delimiter and add each topic
                                            const topicsArray = topic
                                              .split("||")
                                              .map((t) => t.trim())
                                              .filter((t) => t);
                                            topicsArray.forEach((t) => {
                                              if (t) push(t);
                                            });
                                          } else {
                                            // Add as a single topic
                                            push(topic);
                                          }
                                          form.setFieldValue("newTopic", "");

                                          // Touch the topics field to trigger validation
                                          form.setFieldTouched(
                                            "topics",
                                            true,
                                            false,
                                          );
                                        }
                                      }
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        const topic =
                                          form.values.newTopic.trim();
                                        if (topic) {
                                          // Check if the input contains the old delimiter format
                                          if (topic.includes("||")) {
                                            // Split by the delimiter and add each topic
                                            const topicsArray = topic
                                              .split("||")
                                              .map((t) => t.trim())
                                              .filter((t) => t);
                                            topicsArray.forEach((t) => {
                                              if (t) push(t);
                                            });
                                          } else {
                                            // Add as a single topic
                                            push(topic);
                                          }
                                          form.setFieldValue("newTopic", "");
                                        }
                                      }}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>

                                {form.errors.topics && form.touched.topics && (
                                  <div className="invalid-feedback d-block mb-2">
                                    {form.errors.topics}
                                  </div>
                                )}

                                {Array.isArray(form.values.topics) &&
                                form.values.topics.length > 0 ? (
                                  <div className="topics-container">
                                    {form.values.topics.map((topic, index) => (
                                      <div
                                        key={index}
                                        className="topic-tag mb-1 mr-1"
                                      >
                                        <span className="topic-text">
                                          {topic}
                                        </span>
                                        <button
                                          type="button"
                                          className="topic-remove-btn"
                                          onClick={() => {
                                            remove(index);
                                            // Touch the topics field to trigger validation
                                            form.setFieldTouched(
                                              "topics",
                                              true,
                                              false,
                                            );
                                          }}
                                        >
                                          &times;
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className="text-muted mb-3">
                                    <small>
                                      No topics added yet. Enter topics above.
                                    </small>
                                  </div>
                                )}
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="length">Length (word count)</label>
                          <Field
                            type="number"
                            max="1000"
                            min="5"
                            name="length"
                            id="length"
                            className={`form-control ${errors.length && touched.length ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            name="length"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="lists">
                            Include Lists in Content
                          </label>
                          <Field
                            as="select"
                            name="lists"
                            id="lists"
                            className={`form-control ${errors.lists && touched.lists ? "is-invalid" : ""}`}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Field>
                          <ErrorMessage
                            name="lists"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="autoPush">Auto Push to API</label>
                          <Field
                            as="select"
                            name="autoPush"
                            id="autoPush"
                            className={`form-control ${errors.autoPush && touched.autoPush ? "is-invalid" : ""}`}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </Field>
                          <ErrorMessage
                            name="autoPush"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="pushType">Auto Push Type</label>
                          <Field
                            as="select"
                            name="pushType"
                            id="pushType"
                            className={`form-control ${errors.pushType && touched.pushType ? "is-invalid" : ""}`}
                          >
                            <option value="Page">Page</option>
                            <option value="Post">Post</option>
                            {this.locationCPTOption()}
                            {this.serviceAreaCPTOption()}
                          </Field>
                          <ErrorMessage
                            name="pushType"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="nameShortcode">
                            Name Shortcode Replacement
                            <small>
                              {" "}
                              - Uses <b>[name]</b>
                            </small>
                          </label>
                          <Field
                            as="select"
                            name="nameShortcode"
                            id="nameShortcode"
                            className={`form-control ${errors.nameShortcode && touched.nameShortcode ? "is-invalid" : ""}`}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </Field>
                          <ErrorMessage
                            name="nameShortcode"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="geoShortcode">
                            Geo Shortcode Replacement
                            <small>
                              {" "}
                              - Uses <b>[geo] & [region]</b>
                            </small>
                          </label>
                          <Field
                            as="select"
                            name="geoShortcode"
                            id="geoShortcode"
                            className={`form-control ${errors.geoShortcode && touched.geoShortcode ? "is-invalid" : ""}`}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </Field>
                          <ErrorMessage
                            name="geoShortcode"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="requestContext">
                            Additional Context
                          </label>
                          <Field
                            as="textarea"
                            name="requestContext"
                            id="requestContext"
                            rows="4"
                            placeholder="Add context specific to this request in addition to the account level context"
                            className={`form-control ${errors.requestContext && touched.requestContext ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            name="requestContext"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="referenceUrl">Reference URL</label>
                          <Field
                            type="text"
                            name="referenceUrl"
                            id="referenceUrl"
                            placeholder="Accepts a properly formatted URL for content context if you want to include external data (ie: a brand's website)"
                            className={`form-control ${errors.referenceUrl && touched.referenceUrl ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            name="referenceUrl"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="seoData">SEO Data</label>
                      <Field
                        as="textarea"
                        name="seoData"
                        id="seoData"
                        rows="3"
                        className={`form-control ${errors.seoData && touched.seoData ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        name="seoData"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="account-info-container p-3 bg-light mb-3 rounded">
                      <h5>Account Settings</h5>
                      <div className="row">
                        <div className="col-md-4">
                          <p>
                            <b>Excluded Words:</b>{" "}
                            <span className="text-muted">
                              {this.state.excludedWords || "None"}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-4">
                          <p>
                            <b>Content Context:</b>{" "}
                            <span className="text-muted">
                              {this.state.contentContext || "None"}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-4">
                          <p>
                            <b>API Auth User:</b>{" "}
                            <span className="text-muted">
                              {this.state.authUser || "None"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <small className="text-muted">
                        Define excluded words, content context & API auth
                        settings in the Account via the Edit panel
                      </small>
                    </div>

                    <div className="text-right">
                      <button
                        type="submit"
                        className={`btn btn-primary btn-lg ${
                          formik.isSubmitting ||
                          !isValid ||
                          !formik.values.topics ||
                          formik.values.topics.length === 0
                            ? "disabled"
                            : ""
                        }`}
                        disabled={
                          formik.isSubmitting ||
                          !isValid ||
                          !formik.values.topics ||
                          formik.values.topics.length === 0
                        }
                      >
                        {formik.isSubmitting ? "Submitting..." : "Submit Order"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }

  renderContentTable() {
    return (
      <div className="content-table-container mt-4">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3 className="card-title mb-0">Existing Content</h3>
            <span className="badge badge-primary">
              {this.contentListLength()} items
            </span>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table table-hover content-table mb-0">
                <thead className="thead-light">
                  <tr>
                    <th className="d-table-cell">Topic</th>
                    <th className="d-none d-lg-table-cell">Length</th>
                    <th className="d-none d-lg-table-cell">Content</th>
                    <th className="d-table-cell text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>{this.contentList(this.state.role)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAccountHeader() {
    return (
      <div className="account-header-container mb-4">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h1 className="account-title mb-2">{this.state.accountname}</h1>
                <a
                  href={this.state.accounturl}
                  className="account-url"
                  target="_blank"
                  rel="noreferrer"
                >
                  {this.state.accounturl}
                </a>
              </div>
              <div className="col-lg-6">
                <div className="account-stats text-lg-right">
                  {this.renderCrawlingStatus()}
                  <p className="user-info mb-2">
                    <strong>User:</strong> {this.state.user}
                  </p>
                  <div className="stats-container d-flex justify-content-lg-end">
                    <div className="stat-item mr-4">
                      <span className="stat-label">Total Pages:</span>
                      <span className="stat-value">
                        {this.contentListLength()}
                      </span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-label">Pages This Month:</span>
                      <span className="stat-value">
                        {this.contentListMonth()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAccessDenied() {
    return (
      <div className="access-denied-container text-center">
        <div className="card">
          <div className="card-body">
            <h1 className="text-danger mb-3">Access Denied</h1>
            <h2 className="mb-4">
              This account level does not have permission to view account
              content pages.
            </h2>
            <Link to="/dashboard" className="btn btn-primary">
              Return to Dashboard
            </Link>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    return (
      <Can
        role={this.state.role}
        perform="accounts:content"
        yes={() => (
          <div className="account-content-container">
            {this.renderAccountHeader()}
            {this.renderContentForm()}
            {this.renderPendingPages()}
            {this.renderContentTable()}
          </div>
        )}
        no={() => this.renderAccessDenied()}
      />
    );
  }
}

export default withAuth0(AccountContent);
